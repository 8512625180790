import React from "react"

type POISidebarInfoProps = {
  poi: POI
}

export const PoiSidebarInfo = ({ poi }: POISidebarInfoProps) => {
  const description = poi.description as string
  const trivia = poi.trivia as string


  return (
    <>
      {!description ? "" : (
        <div dangerouslySetInnerHTML={{ __html: description }} />
      )}
      {!trivia ? "" : (
        <>
          <h3>Trivia</h3>
          <div dangerouslySetInnerHTML={{ __html: trivia }} />
        </>
      )}
    </>
  )
}