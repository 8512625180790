import styles from "./Sidebar.module.scss"
import React from "react"
import classNames from "classnames"
import MarkerEmblem from "@/components/marker/MarkerEmblem"

type SidebarTitleProps = {
  title?: string,
  marker?: Marker,
}

export function SidebarTitle({ title, marker }: SidebarTitleProps) {
  return (
    <>
      <div className={classNames(styles["sidebar-header"], "sidebar-header")}>
        <div className={styles["sidebar-title"]}>
          {marker && <MarkerEmblem marker={marker} size={44} />}
          <h2>{title}</h2>
        </div>
        <div className={classNames(styles["sidebar-header__wood"])}></div>
      </div>
    </>
  )
}