import React from "react"

type CitySidebarInfoProps = {
  city: City
}

export const CitySidebarInfo = ({ city }: CitySidebarInfoProps) => {
  const description = city.description as string
  const trivia = city.trivia as string

  return (
    <>
      {!description ? "" : (
        <div dangerouslySetInnerHTML={{ __html: description }} />
      )}
      {!trivia ? "" : (
        <>
          <h3>Trivia</h3>
          <div dangerouslySetInnerHTML={{ __html: trivia }} />
        </>
      )}
    </>
  )
}