import React, { useEffect } from "react"
import classNames from "classnames"
import styles from "./SplashScreen.module.scss"

type SplashScreenProps = {
  loading: boolean;
  label?: React.ReactNode;
}

export function SplashScreen({ loading, label }: SplashScreenProps) {
  useEffect(() => {
    if (!loading) {
      const ele = document.getElementsByClassName("app-loading-screen")[0]
      if (ele) {
        ele.classList.add(styles["loading-done"])
      }
    }
  }, [loading])

  return (
    <div className={classNames(styles["app-loading-screen"], "app-loading-screen")}>
      <div className={styles["app-loading-screen--windrose-container"]}>
          <Windrose />
        <div className={styles["app-loading-screen--label"]}>
            {label ? label :  "Daten werden geladen..."}
          </div>
      </div>
    </div>
  )
}

export function Windrose() {
  return (
    <svg className={styles["app-loading-screen--windrose"]} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 512 512" xmlSpace="preserve">
      <g className={styles["app-loading-screen--windrose-rose-bg"]}>
        <path fill="none" stroke="#000000" strokeWidth="0.8413" strokeLinecap="round" strokeLinejoin="round" d="M378.1,256
      c0,67.4-54.7,122.2-122.2,122.2c-67.4,0-122.2-54.7-122.2-122.2c0-67.4,54.7-122.2,122.2-122.2S378.1,188.6,378.1,256L378.1,256z"
        />
        <path fill="none" stroke="#000000" strokeWidth="0.8413" strokeLinecap="round" strokeLinejoin="round" d="M342.8,256
      c0,48-38.9,86.9-86.9,86.9S169,304,169,256s38.9-86.9,86.9-86.9S342.8,208.1,342.8,256L342.8,256L342.8,256z" />
        <path fill="none" stroke="#000000" strokeWidth="0.925" strokeLinecap="round" strokeLinejoin="round" d="M322.4,95.6
      L189.5,416.4" />
        <path fill="none" stroke="#000000" strokeWidth="0.925" strokeLinecap="round" strokeLinejoin="round" d="M378.7,133.3
      L133.2,378.8" />
        <path fill="none" stroke="#000000" strokeWidth="0.925" strokeLinecap="round" strokeLinejoin="round" d="M416.4,189.6
      L95.5,322.5" />
        <path fill="none" stroke="#000000" strokeWidth="0.925" strokeLinecap="round" strokeLinejoin="round" d="M416.4,322.5
      L95.5,189.6" />
        <path fill="none" stroke="#000000" strokeWidth="0.925" strokeLinecap="round" strokeLinejoin="round" d="M378.7,378.8
      L133.2,133.3" />
        <path fill="none" stroke="#000000" strokeWidth="0.925" strokeLinecap="round" strokeLinejoin="round" d="M322.4,416.4
      L189.5,95.6" />

        <path shapeRendering="auto" imageRendering="auto" colorRendering="auto" fill="none" stroke="#000000"
              strokeWidth="0.9775" d="
      M255.9,59.7c-108.3,0-196.3,88-196.3,196.3s88,196.3,196.4,196.3s196.4-88,196.4-196.3C452.3,147.7,364.3,59.7,255.9,59.7
      L255.9,59.7z" />

        <path shapeRendering="auto" imageRendering="auto" colorRendering="auto" fill="none" stroke="#000000"
              strokeWidth="0.9023" d="
      M255.9,81.8c96.2,0,174.3,78,174.3,174.3c0,96.2-78,174.3-174.3,174.3C159.7,430.3,81.7,352.3,81.7,256
      C81.7,159.8,159.7,81.8,255.9,81.8L255.9,81.8z" />

        <line fill="none" stroke="#000000" strokeWidth="0.925" strokeLinecap="round" strokeLinejoin="round"
              x1="255.9"
              y1="81.8" x2="255.9" y2="430.3" />

        <line fill="none" stroke="#000000" strokeWidth="0.925" strokeLinecap="round" strokeLinejoin="round"
              x1="81.7"
              y1="256" x2="430.2" y2="256" />
      </g>


      <g className={styles["app-loading-screen--windrose-rose-bg-ripple"]}>

        <path shapeRendering="auto" imageRendering="auto" colorRendering="auto" fill="none" stroke="#000000"
              strokeWidth="0.9775" d="
      M255.9,59.7c-108.3,0-196.3,88-196.3,196.3s88,196.3,196.4,196.3s196.4-88,196.4-196.3C452.3,147.7,364.3,59.7,255.9,59.7
      L255.9,59.7z" />

        <path shapeRendering="auto" imageRendering="auto" colorRendering="auto" fill="none" stroke="#000000"
              strokeWidth="0.9023" d="
      M255.9,81.8c96.2,0,174.3,78,174.3,174.3c0,96.2-78,174.3-174.3,174.3C159.7,430.3,81.7,352.3,81.7,256
      C81.7,159.8,159.7,81.8,255.9,81.8L255.9,81.8z" />
      </g>
      <g className={styles["app-loading-screen--windrose-rose"]}>
        <g transform="matrix(3.887 1.61 -1.61 3.887 -3521.9 -3706.4)">
          <path fillRule="evenodd" clipRule="evenodd" d="M1169.1,526.5l15.9-4.9l4.9-15.9l4.9,15.9l15.9,4.9l-15.9,4.9l-4.9,15.9
        l-4.9-15.9L1169.1,526.5z" />
          <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1189.2,526.5H1170l15.2-4.7 M1189.9,527.2v19.2l-4.7-15.2
         M1190.7,526.5h19.2l-15.2,4.7 M1189.9,525.7v-19.2l4.7,15.2" />
        </g>
        <g transform="matrix(1.61 3.887 -3.887 1.61 733.16 -5079.6)">
          <path fillRule="evenodd" clipRule="evenodd" d="M1107.3,590.2l15.9-4.9l4.9-15.9l4.9,15.9l15.9,4.9l-15.9,4.9l-4.9,15.9
        l-4.9-15.9L1107.3,590.2z" />
          <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1127.4,590.1h-19.2l15.2-4.7 M1128.2,590.9v19.2l-4.7-15.2
         M1128.9,590.1h19.2l-15.2,4.7 M1128.2,589.4v-19.2l4.7,15.2" />
        </g>
        <g transform="matrix(0 4.6156 -4.6156 0 3184.1 -4726.7)">
          <path fillRule="evenodd" clipRule="evenodd" d="M1060.7,653.4l13.5-18.8l-13.5-18.8l18.8,13.5l18.8-13.5l-13.5,18.8l13.5,18.8
        l-18.8-13.5L1060.7,653.4z" />
          <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1074.6,634.5l4.8,0.1L1062,652L1074.6,634.5z M1079.5,639.4
        l0.1-4.8L1097,652L1079.5,639.4L1079.5,639.4z M1084.4,634.5l-4.8-0.1L1097,617L1084.4,634.5z M1079.5,629.6l-0.1,4.8L1062,617
        L1079.5,629.6L1079.5,629.6z" />
        </g>
        <g transform="matrix(4.2 4.2 -4.2 4.2 -2056.4 -6892.1)">
          <path fillRule="evenodd" clipRule="evenodd" d="M1106.2,595.7l14.3-20l-14.3-20l20,14.3l20-14.3l-14.3,20l14.3,20l-20-14.3
        L1106.2,595.7z" />
          <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M1121.2,575.7l4.8,0.1l-17.4,17.4L1121.2,575.7z M1126.2,580.7
        l0.1-4.8l17.4,17.4L1126.2,580.7L1126.2,580.7z M1131.1,575.7l-4.8-0.1l17.4-17.4L1131.1,575.7z M1126.2,570.8l-0.1,4.8
        l-17.4-17.4L1126.2,570.8L1126.2,570.8z" />
        </g>
      </g>
      <g className={styles["app-loading-screen--windrose-rose-marker"]}>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M451.9,256l-10.6,4.1l-10.6-4.1l10.6-4.1L451.9,256L451.9,256z" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M81.2,256l-10.5,4.2l-10.6-4.1l10.6-4.1L81.2,256L81.2,256z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M256,430.7l4.1,10.6l-4.1,10.6l-4.1-10.6L256,430.7z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M256,81.3l-4.1-10.6l4.1-10.6l4.1,10.6L256,81.3z" />
      </g>
    </svg>
  )
}