import React, { useCallback, useEffect, useMemo, useState } from "react"
import { MapLayout } from "@/layouts/MapLayout"
import { RegionSidebarInfo } from "@/components/sidebar/RegionSidebarInfo"
import { CitySidebarInfo } from "@/components/sidebar/CitySidebarInfo"
import dynamic from "next/dynamic"
import { PoiSidebarInfo } from "@/components/sidebar/PoiSidebarInfo"
import { useRouter } from "next/router"
import { fetchMarkers } from "@/hooks/fetchMarkers"
import { SidebarLoading } from "@/components/sidebar/SidebarLoading"

const DynamicMap = dynamic(() => import("../../components/map/Map"), { ssr: false })

export async function getStaticProps() {
  const typedMarker = await fetchMarkers()
  const markers = [...typedMarker.regions, ...typedMarker.cities, ...typedMarker.pois]

  return {
    props: {
      markers
    }
  }
}

function MapView({ markers }: { markers: Marker[] }) {
  const router = useRouter()
  const [activeMarker, setActiveMarker] = useState<Marker>()
  const [sidebar, setSidebar] = useState<React.JSX.Element>()
  const [isSidebarLoading, setSidebarIsLoading] = useState(false)

  function setSidebarAndMarker(sidebar: JSX.Element, marker: Marker) {
    setSidebar(sidebar)
    setActiveMarker(marker)
    setSidebarIsLoading(false)
  }


  useEffect(() => {
    function getMarker(type: string, slug: string) {
      return markers.filter(m => m.__typename.toLowerCase() === type).find(marker => marker.slug === slug)
    }
    
    (async () => {
      const data = router.query.path

      if (data) {
        const [type, slug] = data as string[]

        const timeout = setTimeout(() => {
          setSidebarIsLoading(true)
          setSidebar(<SidebarLoading />)
        }, 250)

        let marker = getMarker(type, slug)
        setActiveMarker(marker)

        switch (type) {
          case "region": {
            const response = await fetch(`/api/map/region/${slug}`)
            marker = await response.json()
            const sidebar = <RegionSidebarInfo region={marker! as Region} />
            clearTimeout(timeout)
            setSidebarAndMarker(sidebar, marker!)
            break
          }
          case "city": {
            const response = await fetch(`/api/map/city/${slug}`)
            marker = await response.json()
            const sidebar = <CitySidebarInfo city={marker! as City} />
            clearTimeout(timeout)
            setSidebarAndMarker(sidebar, marker!)
            break
          }
          case "poi": {
            const response = await fetch(`/api/map/poi/${slug}`)
            marker = await response.json()
            const sidebar = <PoiSidebarInfo poi={marker! as POI} />
            clearTimeout(timeout)
            setSidebarAndMarker(sidebar, marker!)
            break
          }
        }
      } else {
        setSidebar(undefined)
        setActiveMarker(undefined)
      }
    })()
  }, [router.query.path, markers])
  const [isLoading, setIsLoading] = useState(true)

  const setLoading = useCallback(() => {
    setIsLoading(false)
  }, [])


  const map = useMemo(() => {
    if (markers) {
      return <DynamicMap markers={markers} loaded={setLoading} />
    }
  }, [markers, setLoading])

  const title = useMemo(() => {
    if (activeMarker) {
      return `${activeMarker?.name}`
    }
    return ""
  }, [activeMarker])

  return (
    <MapLayout activeMarker={activeMarker}
               markers={markers}
               title={title}
               map={map}
               isLoading={isLoading}
               sidebar={sidebar}
               isSidebarLoading={isSidebarLoading} />
  )
}

export default MapView