import React, { PropsWithChildren } from "react"
import styles from "./Layout.module.scss"
import classNames from "classnames"
import Head from "next/head"

type LayoutProps = {
  title?: string,
  className?: string
}

export default function Layout({ children, className, title }: PropsWithChildren<LayoutProps>) {
  return (
    <>
      <Head>
        <title>{title ? `GoduS | ${title}` : "GoduS - Das Götteroktet der uneitlen Sieben"}</title>
      </Head>
      <div className={classNames(styles.layout, className)}>
        <div className={styles["content-container"]}>
          {children}
        </div>
      </div>
    </>
  )
}