import React, { useEffect } from "react"
import sidebarStyles from "./Sidebar.module.scss"
import alluenaSidebarStyles from "./AlluenaSidebar.module.scss"
import classNames from "classnames"
import { SidebarLoading } from "@/components/sidebar/SidebarLoading"
import { SidebarTitle } from "@/components/sidebar/SidebarTitle"
import { useRouter } from "next/router"

const HASH_ROUTING_PREFIX = "/alluena/"

export const AlluenaSidebar = () => {
  const router = useRouter()

  const [isOpen, setIsOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [article, setArticle] = React.useState<Article>()

  function loadArticle(url: string) {
    if (!url.includes("#")) {
      return
    }
    
    const hashValue = url.split("#").pop()
    if (!hashValue) return

    const slug = hashValue?.replace(HASH_ROUTING_PREFIX, "")

    {
      (async () => {
        try {
          setIsLoading(true)
          const response = await fetch(`/api/alluena/${slug}`)
          setArticle(await response.json())
        } catch (e) {
          console.error(e)
          setIsOpen(false)
        } finally {
          setIsLoading(false)
        }
      })()
    }
    setIsOpen(hashValue.startsWith(HASH_ROUTING_PREFIX))
  }

  useEffect(() => {
    loadArticle(router.asPath)

    const onHashChanged = (e: HashChangeEvent) => {
      loadArticle(new URL(e.newURL).hash)
    }
    window.addEventListener("hashchange", onHashChanged)

    return () => {
      window.removeEventListener("hashchange", onHashChanged)
    }
  }, [router])

  const closeSidebar = () => {
    window.location.hash = ""
    setIsOpen(false)
  }

  return (
    <div
      className={
        classNames(
          sidebarStyles.sidebar,
          alluenaSidebarStyles.sidebar,
          "sidebar", {
            [sidebarStyles["sidebar--open"]]: isOpen
          })
      }
    >
      {isLoading && <SidebarLoading />}

      {article && (
        <div className="max-h-full overflow-scroll">
          <button onClick={closeSidebar} className="p-3 w-full">
            Schließen
          </button>
          <div className="sticky -top-5">
            <SidebarTitle title={article.title} />
          </div>
          <div className="p-5 overflow-auto">
            <div dangerouslySetInnerHTML={{ __html: article.content }} />
          </div>
        </div>
      )}
    </div>
  )
}