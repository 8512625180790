import classNames from "classnames"

import styles from "./Sidebar.module.scss"
import React, { FunctionComponent, PropsWithChildren, useRef, useState } from "react"
import { SidebarTitle } from "./SidebarTitle"
import { TitleImageCarousel } from "@/components/sidebar/TitleImageCarousel"
import { SidebarLoading } from "@/components/sidebar/SidebarLoading"

type SidebarProps = {
  /**
   * The marker to show
   */
  marker?: Marker,
  /**
   * Show loading indicator as sidebar content is loaded?
   */
  isSidebarLoading: boolean
};

export const Sidebar: FunctionComponent<PropsWithChildren<SidebarProps>> = (props) => {
  const { children, marker } = props
  const [isOpen, setOpen] = useState(true)
  const sidebarRef = useRef<HTMLDivElement>(null)
  const sidebarContentRef = useRef<HTMLDivElement>(null)

  const title = marker?.name
  const images = marker?.images ? marker.images : []

  return (
    <>
      <div ref={sidebarRef}
           className={
             classNames(
               styles.sidebar,
               "sidebar", {
                 [styles["sidebar--open"]]: isOpen
               })
           }
      >

        {props.isSidebarLoading ? <SidebarLoading /> : ""}

        <section className={classNames(styles["sidebar__content"])} ref={sidebarContentRef}>
          {images.length > 0 ?
            <TitleImageCarousel images={images} title={title} /> :
            <div className={classNames(styles["sidebar-img-top"], styles["sidebar-img-top--empty"])} />
          }
          {marker ? <SidebarTitle title={title} marker={marker} /> : ""}
          <div className={classNames(styles["sidebar__content-main"], "m-4", styles["main"])}>
            {children}
          </div>
        </section>

        <div
          className={classNames("draggable-area", styles["sidebar__handle"], isOpen ? styles["sidebar__handle--open"] : "")}>
          <button className={classNames(styles["sidebar__handle__button"])}
                  onClick={() => setOpen(!isOpen)}
                  title={"Seitenleiste schließen"}>
          </button>
        </div>
      </div>
    </>
  )
}