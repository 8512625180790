import { faCity, faMountainCity, faTreeCity } from "@fortawesome/free-solid-svg-icons"

export const findEmpireByCitySlug = (markers: Marker[], slug: string): Marker | undefined => {
  return markers.find((marker: Marker) => marker.slug === slug)
}

export const sortCitiesByType = (cities: City[]) => {
  const CITY_TYPES_INDEXED = ["Capital", "Town", "City"] as CityType[]
  return (cities || [])
    .sort((c1, c2) => c1.name.localeCompare(c2.name))
    .sort((c1, c2) => CITY_TYPES_INDEXED.indexOf(c1.type) - CITY_TYPES_INDEXED.indexOf(c2.type))
}

export const isRegion = (obj: IdType<any>): obj is Region => obj.__typename === "Region" && (obj as Region).type !== "Empire"
export const isEmpire = (obj: IdType<any>): obj is Region => obj.__typename === "Region" && (obj as Region).type === "Empire"
export const isPOI = (obj: IdType<any>): obj is POI => obj.__typename === "POI"
export const isCity = (obj: IdType<any>): obj is City => obj.__typename === "City" && (obj as City).type === "City"
export const isCapital = (obj: IdType<any>): obj is City => obj.__typename === "City" && (obj as City).type === "Capital"
export const isTown = (obj: IdType<any>): obj is City => obj.__typename === "City" && (obj as City).type === "Town"

export function getHref(marker?: Marker) {
  const prefix = location.host === "maps.godus.de" ? "" : "/map"
  
  if (marker) {
    switch (marker.__typename) {
      case "City":
        return `${prefix}/city/${marker.slug}`
      case "Region":
        return `${prefix}/region/${marker.slug}`
      case "POI":
        return `${prefix}/poi/${marker.slug}`
    }
  }
  return prefix
}

export const getCityIcon = (type: CityType) => {
  switch (type) {
    case "Capital":
      return faMountainCity
    case "City":
      return faCity
    case "Town":
    default:
      return faTreeCity
  }
}