import City from "../../public/images/icons/city.png"
import Forest from "../../public/images/icons/forest.png"
import Mountain from "../../public/images/icons/mountains.png"
import Sea from "../../public/images/icons/lake.png"
import Other from "../../public/images/icons/poi.png"

import Ozburn from "../../public/images/icons/capital_ozburn.png"
import Knechtingen from "../../public/images/icons/capital_knechtingen.png"
import Grohe from "../../public/images/icons/capital_grohe.png"
import Tantal from "../../public/images/icons/capital_tantal.png"
import Makula from "../../public/images/icons/capital_makula.png"
import Kupfertino from "../../public/images/icons/capital_kupfertino.png"
import Heiwa from "../../public/images/icons/capital_heiwa.png"


const iconMap: { [key: string]: any } = {
  POI: {
    Forest,
    Mountain,
    Sea,
    Other
  },
  City: {
    City,
    Town: City,
    Capital: {
      ozburn: Ozburn,
      knechtingen: Knechtingen,
      grohe: Grohe,
      tantal: Tantal,
      makula: Makula,
      kupfertino: Kupfertino,
      heiwa: Heiwa
    }
  }
}

export const getMarkerEmblem = (marker: Marker): HTMLImageElement | undefined => {
  return getImageSrcBySlug(marker) ??
    getImageSrcByType(marker) ??
    getImageSrcByTypename(marker)
}

const getImageSrcBySlug = (marker: Marker): HTMLImageElement | undefined => {
  try {
    const value = iconMap[marker.__typename][marker.type][marker.slug]
    if (value !== undefined && value.src === "string") {
      return value
    }
  } catch (e) {
    return
  }
}

const getImageSrcByType = (marker: Marker): HTMLImageElement | undefined => {
  try {
    const value = iconMap[marker.__typename][marker.type]
    if (value !== undefined && typeof value.src === "string") {
      return value
    }
  } catch (e) {
    return
  }
}

const getImageSrcByTypename = (marker: Marker): HTMLImageElement | undefined => {
  try {
    const value = iconMap[marker.__typename]
    if (value !== undefined && typeof value.src === "string") {
      return value
    }
  } catch (e) {
    return
  }
}