import React from "react"
import { useRouter } from "next/router"
import { getCityIcon, sortCitiesByType } from "@/utils/markerUtils"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./RegionCityList.module.scss"
import { Button } from "@material-tailwind/react"

export const RegionCityList: React.FC<{ region: Region }> = ({ region }) => {
  const router = useRouter()

  return (
    <div className={classNames(styles["list-of-cities"], " mt-2")}>
      {sortCitiesByType(region.cities!).map((city, idx) => {
        return (
          <Button onClick={() => router.push(`/map/city/${city.slug}?zoom=6&coords=${city.marker.position.join(",")}`)}
                  key={`${idx}-${city.name}`}
                  className={classNames({
                    [styles["is-capital"]]: city.type === "Capital",
                    [styles["is-town"]]: city.type === "Town",
                    [styles["is-city"]]: city.type === "City"
                  }, "grow shrink-0 mr-1 my-0 mb-1 whitespace-nowrap")}
                  placeholder={undefined}>
            <FontAwesomeIcon icon={getCityIcon(city.type)} className={"pr-2"} />
            {city.name}
          </Button>)
      })}
    </div>
  )
}