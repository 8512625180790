import styles from "./MarkerEnblem.module.scss"
import { getMarkerEmblem } from "@/components/marker/markerEnblemUtils"
import Image from "next/image"

type Props = {
  marker: Marker
  size?: number
}

export default function MarkerEmblem({ marker, size }: Props) {
  const markerEmblem = getMarkerEmblem(marker)
  return (
    markerEmblem !== undefined ? (
      <Image src={markerEmblem.src} className={styles["emblem-icon"]} height={size || 53} width={size || 53}
             alt={markerEmblem.alt || ""} />
    ) : <></>
  )
}